@import "../../variables.scss";

.LandingHelp {
  display: flex;
  width: 100%;
  max-width: 954px;

  justify-content: center;

  padding: 72px 0;

  > .wrap {
    display: flex;
    width: 100%;

    align-items: flex-start;

    @media all and (max-width: $size-848) {
      flex-direction: column-reverse;
      align-items: center;
    }

    > .help-faq {
      display: flex;
      flex-grow: 1;

      flex-direction: column;

      padding-right: 80px;

      @media all and (max-width: $size-848) {
        padding: 0 24px;
        margin-top: 24px;

        > h2 {
          text-align: center;
        }

        > .faq-item + .faq-item {
          margin-top: 40px;
        }
      }

      > .faq-item {
        margin-top: 40px;

        @media all and (max-width: $size-848) {
          text-align: center;
          margin-top: 0;
        }

        > span {
          font-weight: 500;
          font-size: 18px;
        }
        > p {
          color: #373f47;
          font-size: 14px;

          line-height: 1.7;

          margin-top: 20px;

          > a {
            color: #0267c1;
            border-bottom: 1px dotted;
          }
        }
      }
    }

    > .help {
      display: flex;
      flex-shrink: 0;
      flex-direction: column;

      background: #fbf4e3;
      border-radius: 16px;

      padding: 40px;

      > .links {
        display: flex;
        flex-direction: column;

        margin-top: 12px;

        > .link {
          display: flex;
          align-items: center;

          &:not(:first-child) {
            margin-top: 10px;
          }

          > svg {
            margin-right: 10px;
          }

          > a {
            color: #0267c1;

            line-height: 1.3;

            border-bottom: 1px dotted;
          }
        }
      }
    }

    > .help-faq,
    > .help {
      > h2 {
        font-weight: 600;
        margin-bottom: 20px;
      }
    }
  }
}