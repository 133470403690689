@import "../../variables.scss";

.PriceCalculator {
  display: flex;
  width: 100%;
  max-width: 954px;

  flex-direction: column;
  align-items: center;

  padding-top: 74px;
  padding-bottom: 74px;

  @media all and (max-width: $size-848) {
    padding-right: 40px;
    padding-left: 40px;
  }

  > .calculator-title {
    width: 100%;

    font-size: 36px;

    @media all and (max-width: $size-848) {
      text-align: center;
    }

    &.hide {
      display: none;
    }
  }

  > .calculator-tariffs {
    display: flex;
    width: 100%;

    margin-top: 24px;

    @media all and (max-width: 726px) {
      flex-direction: column;
    }

    &.hide {
      display: none;
    }

    > .tariff {
      display: flex;
      align-items: center;

      background: #f7f7f7;

      outline: 1px solid rgba(105, 105, 105, 0.1);
      border-radius: 8px;

      padding: 5px 15px;

      @media all and (max-width: 726px) {
        justify-content: center;
      }

      &:hover {
        cursor: pointer;
      }

      &:not(:first-child) {
        margin-left: 10px;

        @media all and (max-width: 726px) {
          margin-left: 0;
          margin-top: 10px;
        }
      }

      &.active {
        outline: 2px solid rgba(105, 105, 105, 0.75);
      }

      > img {
        pointer-events: none;
        width: 80px;
        margin-left: 20px;
      }
    }
  }

  > .calculator-form {
    display: flex;
    width: 100%;

    margin-top: 14px;

    @media all and (max-width: $size-848) {
      flex-direction: column;
    }

    &.hide {
      display: none;
    }

    > .input {
      display: flex;
      height: 64px;

      flex-shrink: 0;
      flex-grow: 1;
      align-items: center;

      border: 1px solid rgba(105, 105, 105, 0.5);

      padding: 0 16px;

      position: relative;

      &:hover {
        cursor: text;
      }

      &:first-child {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;

        @media all and (max-width: $size-848) {
          border-radius: 0;
        }
      }

      &:last-child {
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;

        @media all and (max-width: $size-848) {
          border-radius: 0;
        }
      }

      > input {
        width: 100%;

        background: unset;
        color: #3b3b3b;

        font-weight: 600;
        font-size: 16px;

        border: unset;

        &::placeholder {
          opacity: 0.75;
          font-weight: 500;
        }

        &:focus::placeholder {
          opacity: 0;
        }
      }

      > label {
        display: none;

        opacity: 0.5;

        font-weight: 400;
        font-size: 13px;

        position: absolute;
        top: 2px;
      }

      > input:focus + label,
      > input:not([value=""]) + label {
        display: block;
      }

      > .input-autocomplete-items {
        display: none;
        width: 100%;
        max-height: 200px;

        overflow: hidden;
        overflow-y: auto;

        flex-direction: column;

        background: #fff;

        border: 1px solid rgba(105, 105, 105, 0.5);
        border-radius: 8px;

        position: absolute;
        top: calc(100% + 4px);
        left: 0;
        z-index: 2;

        > .item {
          display: flex;
          width: 100%;
          height: 50px;

          flex-shrink: 0;
          align-items: center;

          padding: 0 10px;

          &:hover {
            cursor: pointer;
            background: rgba(#000, 0.05);
          }

          &:not(:last-child) {
            border-bottom: 1px solid rgba(#000, 0.15);
          }

          > svg {
            width: 15px;
            height: 15px;

            flex-shrink: 0;

            stroke: rgba(#000, 0.75);
          }

          > span {
            color: rgba(#000, 0.75);

            font-size: 14px;

            margin-left: 10px;
          }
        }
      }

      > input:focus + label + .input-autocomplete-items:not(:empty),
      > .input-autocomplete-items:not(:empty):active {
        display: flex;
      }
    }

    .button-calculator {
      display: flex;
      height: 64px;

      flex-shrink: 0;
      justify-content: center;
      align-items: center;

      border: 1px solid rgba(105, 105, 105, 0.5);

      &:last-child {
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;

        @media all and (max-width: $size-848) {
          border-radius: 0;
        }
      }

      &.button-swap {
        width: 64px;
        height: 64px;

        background: #f0f0f0;

        @media all and (max-width: $size-848) {
          width: 100%;
        }

        &:hover {
          background: #e1e1e1;
        }
        &:active {
          background: #e8e8e8;
        }
      }

      &.button-submit {
        background: #239a54;
        color: #fff;

        font-weight: 400;
        font-size: 18px;

        border: unset;

        padding: 0 24px;

        &:hover {
          background: #1e8a4b;
        }
        &:active {
          background: #20a256;
        }
      }

      > svg {
        margin-right: 4px;
      }
    }
  }

  > .loading-spinner {
    display: flex;
    width: 100%;

    justify-content: center;

    padding-top: 50px;

    > svg {
      width: 100px;
      height: 100px;

      animation: rotate 2s linear infinite;

      @keyframes rotate {
        100% {
          transform: rotate(360deg);
        }
      }

      > .path {
        stroke: hsl(210, 70, 75);
        stroke-linecap: round;
        animation: dash 1.5s ease-in-out infinite;

        @keyframes dash {
          0% {
            stroke-dasharray: 1, 150;
            stroke-dashoffset: 0;
          }
          50% {
            stroke-dasharray: 90, 150;
            stroke-dashoffset: -35;
          }
          100% {
            stroke-dasharray: 90, 150;
            stroke-dashoffset: -124;
          }
        }
      }
    }
  }

  > .result-back {
    width: 100%;

    > span {
      color: #0267c1;
      border-bottom: 1px dotted;

      &:hover {
        cursor: pointer;
        color: #005eb0;
      }
      &:active {
        color: #005098;
      }
    }
  }

  > .result-data {
    display: flex;
    width: 100%;

    flex-direction: column;
    align-items: center;

    padding-top: 50px;

    > .routes {
      display: flex;
      width: 100%;

      align-items: center;

      > .route {
        max-width: 30%;

        font-size: 13px;

        border-bottom: 1px dotted #000;

        position: relative;

        &.to {
          text-align: right;
        }

        > span:first-child {
          color: rgba(#000, 0.5);
          font-size: 14px;

          position: absolute;
          top: -20px;
        }
        &.to > span:first-child {
          right: 0;
        }
      }

      > .line {
        display: block;
        height: 1px;

        flex-grow: 1;

        border-bottom: 1px dashed rgba(#000, 0.5);

        margin: 0 30px;

        position: relative;

        > .distance {
          color: rgba(#000, 0.75);
          position: absolute;
          top: -20px;
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }

    > .price {
      width: 100%;
      text-align: right;
      margin-top: 40px;

      > span:first-child {
        font-size: 20px;
      }
      > span:last-child {
        color: #1e8a4b;
        font-weight: 500;
        font-size: 32px;
      }
    }

    > .button-order {
      font-weight: 400;
      font-size: 22px;

      padding: 0 40px;
      margin-top: 40px;
    }
  }
}