footer {
  display: flex;
  width: 100%;

  flex-direction: column;
  align-items: center;

  background: #263238;
  color: #fff;

  padding: 40px 0;

  > .cities {
    display: flex;

    column-gap: 80px;

    > .column {
      display: flex;
      flex-direction: column;

      > a {
        color: #cccccc;
        margin-top: 15px;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  > span:last-child {
    margin-top: 40px;
  }
}