.Page404 {
  display: flex;
  width: 100%;

  flex-grow: 1;
  justify-content: center;
  align-items: center;

  background: url("./assets/404_background.png") no-repeat center center;
  background-size: cover;

  padding: 0 10px;

  > .text {
    text-align: center;

    padding: 10px;
    position: relative;

    > span {
      color: #fff;

      font-weight: 500;
      font-size: 54px;

      text-shadow: 0 0 1px #000;

      position: relative;
      z-index: 1;
    }

    &:before {
      content: "";
      display: block;

      background: #000;
      filter: blur(40px);

      position: absolute;
      top: -10px;
      right: -10px;
      bottom: -10px;
      left: -10px;
    }
  }
}