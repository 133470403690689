@import "../../variables.scss";

.LandingFeatures {
  display: flex;
  width: 100%;

  justify-content: center;

  background: #f2f2f2;

  > .wrap {
    display: flex;
    width: 954px;

    flex-direction: column;
    align-items: center;

    padding: 72px 0;

    > .title {
      color: #000;
      font-weight: 500;
      font-size: 36px;

      padding-bottom: 56px;
    }

    > .features-list {
      display: flex;
      width: 100%;

      align-items: flex-start;

      > .features-item {
        display: flex;
        width: calc(100% / 3);

        flex-direction: column;
        align-items: center;

        padding: 0 24px;

        @media all and (max-width: $size-848) {
          padding: 0 4px;
        }

        > svg {
          width: 54px;
          height: 54px;
          margin-bottom: 16px;
        }

        > span {
          color: #000;
          font-weight: 400;
          font-size: 18px;

          text-align: center;
        }
      }
    }
  }
}