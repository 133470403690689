@font-face {
  font-family: MazzardH;
  font-weight: 900;
  src: url("./assets/fonts/MazzardH-Black.ttf");
}
@font-face {
  font-family: MazzardH;
  font-weight: 800;
  src: url("./assets/fonts/MazzardH-ExtraBold.ttf");
}
@font-face {
  font-family: MazzardH;
  font-weight: 700;
  src: url("./assets/fonts/MazzardH-Bold.ttf");
}
@font-face {
  font-family: MazzardH;
  font-weight: 600;
  src: url("./assets/fonts/MazzardH-SemiBold.ttf");
}
@font-face {
  font-family: MazzardH;
  font-weight: 500;
  src: url("./assets/fonts/MazzardH-Medium.ttf");
}
@font-face {
  font-family: MazzardH;
  font-weight: 400;
  src: url("./assets/fonts/MazzardH-Regular.ttf");
}
@font-face {
  font-family: MazzardH;
  font-weight: 300;
  src: url("./assets/fonts/MazzardH-Light.ttf");
}
@font-face {
  font-family: MazzardH;
  font-weight: 200;
  src: url("./assets/fonts/MazzardH-ExtraLight.ttf");
}
@font-face {
  font-family: MazzardH;
  font-weight: 100;
  src: url("./assets/fonts/MazzardH-Thin.ttf");
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

a, a:hover, a:active, a:visited {
  text-decoration: none;
}

input:active, input:focus {
  outline: none;
}

body {
  display: flex;
  width: 100%;
  min-height: 100vh;

  overflow-x: hidden;

  background: #fff;
  color: #000;

  font-family: MazzardH, sans-serif;
}
body.overflow-hidden {
  overflow: hidden;
}

#root {
  display: flex;
  width: 100%;

  flex-grow: 1;
}

.App {
  display: flex;
  width: 100%;

  flex-grow: 1;
  flex-direction: column;
  align-items: center;
}