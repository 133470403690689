@import "../../variables.scss";

.Header.navbar {
  display: flex;
  width: 100%;
  height: 66px;

  justify-content: space-between;
  align-items: center;

  background: #fff;

  box-shadow: 0 2px 16px 0 rgba(55, 63, 71, 0.16);

  padding: 18px 24px 18px 40px;

  @media all and (max-width: $size-848) {
    justify-content: center;
  }

  > .navbar__brand {
    width: fit-content;
    height: 54px;
    padding-top: 8px;

    > img {
      pointer-events: none;
      height: 46px;
    }
  }

  > .navbar__links {
    display: flex;

    @media all and (max-width: $size-848) {
      display: none;
    }

    > a {
      color: #6c7c8c;
      font-size: 13px;

      &:hover {
        text-decoration: underline;
      }

      &:not(:first-child) {
        margin-left: 24px;
      }
    }
  }

  > .navbar__mobile-links {
    display: none;
    width: 100%;

    background: #fff;

    padding-top: 90px;

    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: $z-index-navbar-mobile-links;

    > .links {
      display: flex;
      width: 100%;

      flex-direction: column;
      align-items: center;

      > a {
        color: #6c7c8c;
        font-weight: 500;
        font-size: 20px;

        text-align: center;

        &:hover {
          text-decoration: underline;
        }

        &:not(:first-child) {
          margin-top: 24px;
        }
      }
    }

    @media all and (max-width: $size-848) {
      display: flex;
    }
  }

  > .navbar__mobile-burger {
    display: none;
    width: 50px;
    height: 50px;

    position: absolute;
    top: 8px;
    right: 8px;
    z-index: $z-index-navbar-mobile-burger;

    @media all and (max-width: $size-848) {
      display: flex;
    }
  }
}