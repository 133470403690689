@import "../../variables.scss";

.TopBanner {
  display: flex;
  width: 100%;
  height: 525px;

  justify-content: center;

  background: #010101;

  position: relative;

  &:before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;

    background: url("../../assets/img/top-banner-background.png") no-repeat right center;
    background-size: cover;

    position: absolute;
  }

  > .TopBanner__content {
    display: flex;
    width: 100%;
    max-width: 954px;
    height: 100%;

    position: relative;
    z-index: 1;

    > .phone-call {
      display: flex;
      height: 100%;

      flex-direction: column;
      justify-content: center;
      align-items: flex-start;

      @media all and (max-width: $size-848) {
        width: 100%;
        align-items: center;
        text-align: center;
      }
    }

    > .socials-wrapper {
      display: flex;
      align-items: center;

      color: #fff;

      position: absolute;
      bottom: 16px;
      left: 0;

      @media all and (max-width: $size-848) {
        width: 100%;
        justify-content: center;
        background: rgba(#000, 0.75);
        padding: 20px 0;
        bottom: 0;
      }

      > h1 {
        font-weight: 400;
        font-size: 20px;

        margin-bottom: 0;

        @media all and (max-width: 492px) {
          display: none;
        }
      }

      > a {
        display: flex;
        align-items: center;

        margin: 0 10px;

        > span {
          color: #fff;
          font-weight: 500;

          text-decoration: underline;

          margin-left: 3px;
        }
      }
    }

    > .phone-call > h1,
    > .socials-wrapper > h1 {
      color: #fff;
      font-weight: 500;
      font-size: 56px;

      text-shadow: 0 0 1px #000;

      margin-bottom: 20px;

      position: relative;

      @media all and (max-width: 492px) {
        font-size: 42px;
      }

      &:before {
        content: "";
        display: block;

        background: #000000;
        filter: blur(70px);

        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
      }
    }
    > .socials-wrapper > .text {
      font-weight: 400;
      font-size: 20px;

      margin-bottom: 0;
    }
  }
}