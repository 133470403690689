@import "../../variables.scss";

.Policy {
  display: block;
  width: 100%;

  flex-grow: 1;

  background: #f2f2f2;

  padding: 60px 240px;

  @media all and (max-width: $size-848) {
    padding: 60px;
  }
}