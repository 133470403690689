@import "../../variables.scss";

.Main {
  display: flex;
  width: 100%;

  flex-direction: column;
  align-items: center;

  > .map-wrapper {
    width: 100%;
    height: 500px;

    overflow: hidden;

    > #map {
      width: 100%;
      height: 100%;
    }
  }
}